import React, { useEffect, useState } from 'react';

//
import {
  Column,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
} from '@tanstack/react-table';
import {
  Box, Button, Input, Link, Text, Select, Table, Tbody, Td, Th, Thead, Tr, Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Subscription, SubscriptionStatus } from '../../features/subscriptions/subscriptions';
import { useAppSelector } from '../../app/hooks';
import { Plan } from '../../features/plans/plans';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<Subscription>> = {
  cell: ({
    getValue, row: { index }, column: { id }, table,
  }) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Input
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  },
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export interface SubscriptionsProps {
  data: Subscription[]
  plans: Plan[]
}

export const SubscriptionsTable = ({ data, plans }: SubscriptionsProps) => {
  const rerender = React.useReducer(() => ({}), {})[1];

  const columns = React.useMemo<ColumnDef<Subscription>[]>(
    () => [
      {
        header: 'Subscription',
        footer: (params) => params.column.id,
        columns: [
          {
            accessorKey: 'id',
            header: 'ID',
            accessorFn: (row) => row?.id.replaceAll('SUBSCRIPTION#', ''),
            footer: (params) => params.column.id,
          },
          {
            accessorFn: (row) => row.avc_id,
            id: 'avc_id',
            header: 'AVC',
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'IP',
            accessorFn: (row) => row.ip,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'IP6',
            accessorFn: (row) => row.ip6,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'IP6-DP',
            accessorFn: (row) => row.delegated_ip6,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'State',
            accessorFn: (row) => row.state,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Tech',
            accessorFn: (row) => row?.technology_type,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'SVLAN',
            accessorFn: (row) => row.svlan,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'CVLAN',
            accessorFn: (row) => row.cvlan,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Email',
            accessorFn: (row) => row?.customer_id.replaceAll('CUSTOEMR#', ''),
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Plan',
            accessorFn: (row) => plans?.find((plan: Plan) => (plan.id === row.plan_id))?.name || '',
            footer: (params) => params.column.id,
          },
        ],
      },
    ],
    [],
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
  });
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = () => {
    table.setSorting([{ id: 'created_at', desc: sortOrder === 'asc' }]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const navigate = useNavigate();
  return (
    <Box mx="auto" p="4" borderWidth="1px" borderRadius="md" boxShadow="md" overflowX="auto">
      <Table variant="simple" size="sm" minWidth={{ base: '100%', md: '400px' }}>
        <Thead>
          <Tr>
            <Th textAlign="center">Stripe</Th>
            <Th textAlign="center">Loc ID</Th>
            <Th textAlign="center">SLC ID</Th>
            <Th
              textAlign="center"
              cursor="pointer"
              onClick={handleSort}
              display={{ base: 'table-cell', md: 'table-cell' }}
            >
              Created At
              {' '}
              {sortOrder === 'asc' ? '▲' : '▼'}
            </Th>
            {table.getHeaderGroups().map((headerGroup) => headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                display={header.column.id === 'id' ? 'none' : { base: 'none', md: 'table-cell' }} // Hide on mobile
                textAlign="center"
              >
                {header.isPlaceholder ? null : (
                  <Box>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Box>
                )}
              </Th>
            ))).slice(1)}
          </Tr>
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              _hover={{ bg: 'blue.100', color: 'black' }}
              bg={row.original.status === SubscriptionStatus.Active ? 'green.100' : 'transparent'}
              color={row.original.status === SubscriptionStatus.Active ? 'black' : 'white'}
            >
              <Td textAlign="center" p={2}>
                {row.original.stripe_subscription_id ? (
                  <Link
                    href={`https://dashboard.stripe.com/subscriptions/${row.original.stripe_subscription_id}`}
                    isExternal
                    fontSize="xs"
                    color="blue.500"
                    textDecoration="underline"
                  >
                    <Text isTruncated maxWidth="80px">
                      {row.original.stripe_subscription_id}
                    </Text>
                  </Link>
                ) : (
                  '-'
                )}
              </Td>
              <Td textAlign="center" p={2}>
                {row.original.location_id ? (
                  <Link
                    isExternal
                    color="blue.500"
                    textDecoration="underline"
                    fontSize="xs"
                    href={`https://www.neptune.net.au/internet?locID=${row.original.location_id}`}
                  >
                    <Text isTruncated maxWidth="80px">
                      {row.original.location_id}
                    </Text>
                  </Link>
                ) : (
                  '-'
                )}
              </Td>

              <Td textAlign="center" p={2}>
                {row.original.location_id ? (
                  <Link
                    isExternal
                    color="blue.500"
                    fontSize="xs"
                    textDecoration="underline"
                    href={`https://portal.superloop.com/360/service/${row.original.aggregator_service_id}`}
                  >
                    {row.original.aggregator_service_id}
                  </Link>
                ) : (
                  '-'
                )}
              </Td>
              <Td
                onClick={() => navigate(`${row.original.id.replaceAll('SUBSCRIPTION#', '')}`)}
                textAlign="center"
                p={2}
              >
                {new Date(row.original.created_at * 1000).toLocaleDateString()}
                {' '}
                {/* Format date */}
              </Td>

              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  display={cell.column.id === 'id' ? 'none'
                    : { base: 'none', md: 'table-cell' }} // Hide on mobile
                  onClick={() => navigate(`${row.getVisibleCells()[0].getValue()}`)}
                  textAlign="center"
                  p={2}
                >
                  <Box>{cell.getValue() as string}</Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Pagination Controls */}
      <Box display="flex" alignItems="center" gap="2" mt="4" flexWrap="wrap" justifyContent="center">
        <Button size="sm" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </Button>
        <Button size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </Button>
        <Button size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          {'>'}
        </Button>
        <Button size="sm" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
          {'>>'}
        </Button>
        <Box mx="2">
          <strong>
            Page
            {' '}
            {table.getState().pagination.pageIndex + 1}
            {' '}
            of
            {' '}
            {table.getPageCount()}
          </strong>
        </Box>
        <Box display="flex" alignItems="center" gap="2">
          Go to page:
          <Input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            width="60px"
            size="sm"
          />
          <Select
            size="sm"
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            width="fit-content"
          >
            {[100, 200, 300, 400, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show
                {' '}
                {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionsTable;
