import React, { useEffect } from 'react';

//
import {
  Column,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
} from '@tanstack/react-table';
import {
  Box, Button, Input, Link, Select, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Plan } from '../../features/plans/plans';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<Plan>> = {
  cell: ({
    getValue, row: { index }, column: { id }, table,
  }) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Input
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  },
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export interface PlansProps {
  data: Plan[]
}

export const PlansTable = ({ data }: PlansProps) => {
  const rerender = React.useReducer(() => ({}), {})[1];

  const columns = React.useMemo<ColumnDef<Plan>[]>(
    () => [
      {
        header: 'Plan',
        footer: (params) => params.column.id,
        columns: [
          {
            accessorFn: (row) => row?.id.replaceAll('PLAN#', ''),
            visible: false,
            id: 'id',
            header: 'ID',
            footer: (params) => params.column.id,
          },
          {
            accessorFn: (row) => row.neptune_id,
            id: 'neptune_id',
            header: 'Neptune ID',
            footer: (params) => params.column.id,
          },
          {
            accessorFn: (row) => row.aggregator_plan_id,
            id: 'aggregator_plan_id',
            header: 'Aggregator plan ID',
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'down_mbps',
            header: 'Down Speed',
            accessorFn: (row) => row.down_mbps,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'up_mbps',
            accessorFn: (row) => row.up_mbps,
            header: 'Up Speed',
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'cost',
            accessorFn: (row) => `$ ${row.cost}`,
            header: 'Cost',
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'discount',
            accessorFn: (row) => row.discount,
            header: 'Discount',
            footer: (params) => params.column.id,
          },
        ],
      },
    ],
    [],
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    debugTable: true,
  });

  const navigate = useNavigate();

  return (
    <Box p="4">
      <Table variant="simple" size="xs" mb="4">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr _hover={{ bg: 'white' }} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  display={header.id === 'id' ? 'none' : undefined}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <Box>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanFilter() && (
                      <Box mt="2" />
                      )}
                    </Box>
                  )}
                </Th>
              ))}
              <Th>Stripe product id</Th>
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              _hover={{ bg: 'teal.800', color: 'white' }}
              bg={row.original.despina_display ? 'green.500' : 'transparent'}
              key={row.id}
            >

              <Td p="4" textAlign="center">
                {row.original.stripe_product_id ? (
                  <Link
                    href={`https://dashboard.stripe.com/products/d/${row.original.stripe_product_id}`}
                    isExternal
                    color="white.500"
                    _hover={{ color: 'pink.200' }}
                    textDecoration="underline"
                  >
                    {row.original.stripe_product_id}
                  </Link>
                ) : (
                  '-'
                )}
              </Td>
              {row.getVisibleCells().map((cell) => (
                <Td
                  display={cell.column.id === 'id' ? 'none' : 'table-cell'}
                  key={cell.id}
                  onClick={() => navigate(`${row.getVisibleCells()[0].getValue()}`)}
                >
                  <Box p={4}>
                    {cell.getValue() as string}
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Box display="flex" alignItems="center" gap="4" mt="4">
        <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </Button>
        <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </Button>
        <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          {'>'}
        </Button>
        <Button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
          {'>>'}
        </Button>
        <Box mx="2">
          <strong>
            Page
            {' '}
            {table.getState().pagination.pageIndex + 1}
            {' '}
            of
            {' '}
            {table.getPageCount()}
          </strong>
        </Box>
        <Box display="flex" alignItems="center" gap="2">
          Go to page:
          <Input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            width="70px"
          />
          <Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            width="fit-content"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show
                {' '}
                {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Box>

      <Box mt="4">
        <Box>
          {table.getRowModel().rows.length}
          {' '}
          Rows
        </Box>
        <Button onClick={() => rerender()} mt="2">
          Force Rerender
        </Button>
      </Box>
    </Box>
  );
};
