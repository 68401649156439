import React, { useEffect, useState } from 'react';
import {
  FormState, useForm, UseFormReturn, UseFormStateReturn,
} from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spinner,
  Select,
  Flex,
  VStack,
  Box,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import subscriptions, {
  createSubscription, fetchCurrentSubscription, fetchSubscriptions, Subscription, SubscriptionStatus, updateSubscription,
} from '../features/subscriptions/subscriptions';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { fetchCustomers } from '../features/customers/customers';
import { FormControlInput } from './forms';
import { NeptuneAutoComplete } from './forms/NeptuneAutoComplete';

export const SubscriptionForm = () => {
  const [subscription, setSubscription] = useState({} as Subscription);

  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptions);
  const customersState = useAppSelector((state: RootState) => state.customers);
  const plansState = useAppSelector((state: RootState) => state.plans);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    register, reset, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: {
      ...subscription,
    },
  });

  useEffect(() => {
    dispatch(fetchCustomers());
    setLoading(true);
    if (subscriptionId !== '') {
      dispatch(fetchCurrentSubscription(subscriptionId));
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (subscriptionId !== '') {
      setSubscription(subscriptionState.current);
      reset(subscriptionState.current);
    }
  }, [subscriptionState.current, subscriptionState.status]);

  const onSubmit = (values: any) => {
    if (subscriptionId) {
      dispatch(updateSubscription({ ...values, id: `SUBSCRIPTION#${subscriptionId}`, status: +values.status }));
    } else {
      dispatch(createSubscription(values));
    }

    subscriptionState.value.forEach((sub) => {
      if (sub.stripe_subscription_id === values.stripe_subscription_id) {
        navigate('/dashboard/subscriptions');
      }
    });
  };

  if (subscriptionState.status === 'loading' || loading) {
    return <Spinner />;
  }

  return (
    <Box maxWidth="600px" mx="auto" p="6" borderWidth="1px" borderRadius="md" boxShadow="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="4">
          <FormControl isInvalid={!!errors.customer_id}>
            <FormLabel htmlFor="customer_id">Customer</FormLabel>
            <Select
              id="customer_id"
              {...register('customer_id', { required: 'Customer is required' })}
            >
              {customersState.value.map((cust) => (
                <option key={cust.id} value={cust.id}>
                  {cust.id}
                  {' '}
                  |
                  {' '}
                  {cust.stripe_customer_id}
                  {' '}
                  |
                  {' '}
                  {cust.first_name}
                  {' '}
                  {cust.last_name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.customer_id?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.stripe_subscription_id}>
            <FormLabel htmlFor="stripe_subscription_id">Stripe Subscription</FormLabel>
            <Input
              id="stripe_subscription_id"
              type="text"
              {...register('stripe_subscription_id', {
                validate: (val) => val.startsWith('sub_') || "Stripe subscription must start with 'sub_'",
              })}
            />
            <FormErrorMessage>{errors.stripe_subscription_id?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.status}>
            <FormLabel htmlFor="status">Subscription Status</FormLabel>
            <Select
              id="status"
              {...register('status', { required: 'Subscription status is required' })}
            >
              {Object.entries(SubscriptionStatus)
                .filter(([key]) => Number.isNaN(Number(key))) // Filter out numeric enum keys
                .map(([key, value]) => (
                  <option key={value} value={Number(value)}>
                    {key}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{errors.status?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.aggregator_service_id}>
            <FormLabel htmlFor="aggregator_service_id">Superloop Service</FormLabel>
            <Input
              id="aggregator_service_id"
              type="text"
              {...register('aggregator_service_id', {
                validate: (val) => /^\d+$/.test(val) || 'Superloop service ID must be a number',
              })}
            />
            <FormErrorMessage>{errors.aggregator_service_id?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.avc_id}>
            <FormLabel htmlFor="avc_id">AVC</FormLabel>
            <Input
              id="avc_id"
              type="text"
              {...register('avc_id', {
                validate: (val) => /^AVC\d+/.test(val) || "AVC ID must start with 'AVC' followed by numbers",
              })}
            />
            <FormErrorMessage>{errors.avc_id?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.location_id}>
            <FormLabel htmlFor="location_id">Location ID</FormLabel>
            <Input
              id="location_id"
              type="text"
              {...register('location_id', {
                validate: (val) => /^LOC\d+/.test(val) || "Location ID must start with 'LOC' followed by numbers",
              })}
            />
            <FormErrorMessage>{errors.location_id?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.ip}>
            <FormLabel htmlFor="ip">IP Address</FormLabel>
            <Input
              id="ip"
              type="text"
              {...register('ip', {
                validate: (val) => /^103\.252\.(194|195)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/32$/.test(val)
                  || 'IP address is invalid',
              })}
            />
            <FormErrorMessage>{errors.ip?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.ip6}>
            <FormLabel htmlFor="ip6">IP6 Address</FormLabel>
            <Input
              id="ip6"
              type="text"
              {...register('ip6', {
                validate: (val) => /^2401:2520:.+$/.test(val)
                  || 'IP6 address is invalid',
              })}
            />
            <FormErrorMessage>{errors.ip6?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.delegated_ip6}>
            <FormLabel htmlFor="delegated_ip6">IP6-DP</FormLabel>
            <Input
              id="delegated_ip6"
              type="text"
              {...register('delegated_ip6', {
                validate: (val) => /^2401:2520:.+$/.test(val)
                  || 'IP6 address is invalid',
              })}
            />
            <FormErrorMessage>{errors.delegated_ip6?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.svlan}>
            <FormLabel htmlFor="svlan">SVLAN</FormLabel>
            <Input
              id="svlan"
              type="text"
              {...register('svlan', {
                required: 'svlan is required',
              })}
            />
            <FormErrorMessage>{errors.svlan?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.cvlan}>
            <FormLabel htmlFor="cvlan">CVLAN</FormLabel>
            <Input
              id="cvlan"
              type="text"
              {...register('cvlan', {
                required: 'cvlan is required',
              })}
            />
            <FormErrorMessage>{errors.cvlan?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.plan_id}>
            <FormLabel htmlFor="plan_id">Plan</FormLabel>
            <Select
              id="plan_id"
              {...register('plan_id', { required: 'Plan is required' })}
            >
              {plansState.value.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.neptune_id}
                  {' '}
                  | $
                  {plan.cost}
                  {' '}
                  |
                  {' '}
                  {plan.stripe_product_id}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.plan_id?.message}</FormErrorMessage>
          </FormControl>

          {/* Additional fields */}
          <FormControl isInvalid={!!errors.formatted_address}>
            <FormLabel htmlFor="formatted_address">Address</FormLabel>
            <Input
              id="formatted_address"
              type="text"
              {...register('formatted_address', { required: 'Address is required' })}
            />
            <FormErrorMessage>{errors.formatted_address?.message}</FormErrorMessage>
          </FormControl>

          <Button mt={4} colorScheme="teal" type="submit">
            <Flex align="center" p="2" borderRadius="lg" cursor="pointer">
              Submit
            </Flex>
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
