import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter, Navigate, Outlet, Route, Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  Center, ChakraProvider, extendTheme, Spinner,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { RootState, store } from './app/store';
import { fetchMe } from './features/me/me';
import './index.css';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import { fetchSubscriptions } from './features/subscriptions/subscriptions';
import { Subscriptions } from './components/Subscriptions';
import { SubscriptionForm } from './components/SubscriptionForm';
import { fetchPlans } from './features/plans/plans';
import { Plans } from './components/Plans';
import { PlanForm } from './components/PlanForm';
import customers, { fetchCustomers } from './features/customers/customers';
import { Customers } from './components/Customers';
import { CustomerForm } from './components/CustomerForm';

const container = document.getElementById('root')!;
const root = createRoot(container);

export interface Decoded {
  name: string;
  picture: string;
}

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
        bg: 'black',
        color: 'white',
      },
    },
  },
  colors: {
    teal: {
      500: '#00ffff',
    },
    pink: {
      100: '#f72585',
      200: '#f72585',
      300: '#f72585',
      400: '#f72585',
      500: '#f72585',
      600: '#f72585',
      700: '#f72585',
      800: '#f72585',
      900: '#f72585',
    },
    brand: {
      100: '#00ffff',
      900: '##f72585',
    },
  },
});

const Router = () => {
  const meState = useAppSelector((state: RootState) => state.me);

  const dispatch = useAppDispatch();
  useEffect(
    () => {
      dispatch(fetchMe(meState.value.token));
    },
    [meState.value.authenticate],
  );

  const subscriptionState = useAppSelector((state: RootState) => state.subscriptions);
  const plansSstate = useAppSelector((state: RootState) => state.plans);
  const customersState = useAppSelector((state: RootState) => state.customers);

  const [subscriptions, setSubscriptions] = useState(subscriptionState.value);
  useEffect(() => {
    dispatch(fetchSubscriptions());
    dispatch(fetchPlans());
    dispatch(fetchCustomers());
  }, []);
  useEffect(() => {
    setSubscriptions(subscriptionState.value);
  }, [subscriptionState?.value]);

  if (subscriptionState.status === 'loading') {
    <Center>
      <Spinner color="teal.500" speed="0.65s" thickness="4px" variant="xxl" marginTop="10%" />
    </Center>;
  }

  if (meState.status === 'loading') {
    return (
      <div>Loading</div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="dashboard"
          element={(
            <ProtectedRoute
              auth={meState.value.authenticate === 'success'}
              authenticationPath="/login"
              outlet={<Dashboard><Outlet /></Dashboard>}
            />
          )}
        >
          <Route
            path="plans"
          >
            <Route index element={<Plans data={plansSstate.value} />} />
            <Route path="create" element={<PlanForm />} />
            <Route path=":planId" element={<PlanForm />} />
          </Route>
          <Route
            path="subscriptions"
          >
            <Route index element={<Subscriptions data={subscriptions} plans={plansSstate.value} />} />
            <Route path="create" element={<SubscriptionForm />} />
            <Route path=":subscriptionId" element={<SubscriptionForm />} />
          </Route>
          <Route
            path="customers"
          >
            <Route index element={<Customers data={customersState.value} />} />
            <Route path="create" element={<CustomerForm />} />
            <Route path=":customerId" element={<CustomerForm />} />
          </Route>
        </Route>
        <Route
          path="/login"
          element={(
            <div>
              <Login />
            </div>
        )}
        />

        <Route
          path="/"
          element={<Navigate to="/dashboard/subscriptions" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId="829763310492-4thnfhnk4kiaii8toi1jdter2bv35ujl.apps.googleusercontent.com"
      >
        <ChakraProvider theme={theme}>
          <Router />
        </ChakraProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
);
