import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn, UseFormStateReturn } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spinner,
  Text,
  NumberInput,
  Box,
  Select,
  Heading,
  VStack,
  NumberInputField,
  Checkbox,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPlan, fetchCurrentPlan, fetchPlans, Plan, updatePlan,
} from '../features/plans/plans';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { fetchCustomers } from '../features/customers/customers';
import { FormControlInput } from './forms';
import { NeptuneAutoComplete } from './forms/NeptuneAutoComplete';

export const PlanForm = () => {
  const emptyPlan = {
  } as Plan;

  const [plan, setPlan] = useState({} as Plan);

  const {
    register, reset, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: {
      ...plan,
    },
  });

  const { planId } = useParams();
  const dispatch = useAppDispatch();
  const plansState = useAppSelector((state: RootState) => state.plans);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (planId) {
      dispatch(fetchCurrentPlan({ id: planId } as Plan));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (planId !== '' && plansState?.current?.cost !== undefined) {
      setPlan(plansState.current);
      reset(plansState.current);
    }
  }, [plansState.current]);

  const onSubmit = (values: any) => {
    values = {
      ...values,
      up_mbps: Number(values.up_mbps),
      down_mbps: Number(values.down_mbps),
      cost: Number(values.cost),
      discount: Number(values.discount),
    };
    if (planId) {
      dispatch(updatePlan(values));
    } else {
      dispatch(createPlan(values));
    }

    navigate('/dashboard/plans');
  };

  if (plansState.status === 'loading' || loading || (planId && !plansState?.current?.cost)) {
    return <Spinner />;
  }

  return (
    <Box mx="10" p="6" borderWidth="1px" borderRadius="md" boxShadow="md" overflowX="auto">
      <Heading as="h2" size="lg" mb="6" textAlign="center">
        Add a new plan or update
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="4">
          {plansState.current?.id && (
            <FormControl id="id" isRequired={!!plansState?.current?.id}>
              <FormLabel>ID</FormLabel>
              <Input type="text" {...register('id')} />
            </FormControl>
          )}

          <FormControl id="aggregator_plan_id" isRequired>
            <FormLabel>Aggregator Plan ID</FormLabel>
            <Input type="text" {...register('aggregator_plan_id', { required: 'Aggregator Plan ID is required' })} />
            {errors.aggregator_plan_id && <Text color="red.500" fontSize="sm">{errors.aggregator_plan_id.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="cost" isRequired>
            <FormLabel>Cost</FormLabel>
            <NumberInput>
              <NumberInputField {...register('cost', { required: 'Cost is required' })} />
            </NumberInput>
            {errors.cost && <Text color="red.500" fontSize="sm">{errors.cost.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="discount" isRequired>
            <FormLabel>Discount</FormLabel>
            <NumberInput>
              <NumberInputField {...register('discount', { required: 'Discount is required' })} />
            </NumberInput>
            {errors.discount && <Text color="red.500" fontSize="sm">{errors.discount.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="billing_cycle" isRequired>
            <FormLabel>Billing Cycle</FormLabel>
            <Select {...register('billing_cycle', { required: 'Billing cycle is required' })}>
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
            </Select>
            {errors.billing_cycle && <Text color="red.500" fontSize="sm">{errors.billing_cycle.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="description" isRequired>
            <FormLabel>Description</FormLabel>
            <Input type="text" {...register('description', { required: 'Description is required' })} />
            {errors.description && <Text color="red.500" fontSize="sm">{errors.description.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="down_mbps" isRequired>
            <FormLabel>Download Mbps</FormLabel>
            <NumberInput>
              <NumberInputField {...register('down_mbps', { required: 'Download Mbps is required' })} />
            </NumberInput>
            {errors.down_mbps && <Text color="red.500" fontSize="sm">{errors.down_mbps.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="up_mbps" isRequired>
            <FormLabel>Upload Mbps</FormLabel>
            <NumberInput>
              <NumberInputField {...register('up_mbps', { required: 'Upload Mbps is required' })} />
            </NumberInput>
            {errors.up_mbps && <Text color="red.500" fontSize="sm">{errors.up_mbps.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" {...register('name', { required: 'Name is required' })} />
            {errors.name && <Text color="red.500" fontSize="sm">{errors.name.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="neptune_id" isRequired>
            <FormLabel>Neptune ID</FormLabel>
            <Input type="text" {...register('neptune_id', { required: 'Neptune ID is required' })} />
            {errors.neptune_id && <Text color="red.500" fontSize="sm">{errors.neptune_id.message?.toString() || ''}</Text>}
          </FormControl>

          <FormControl id="stripe_product_id" isRequired>
            <FormLabel>Stripe Product ID</FormLabel>
            <Input type="text" {...register('stripe_product_id', { required: 'Stripe Product ID is required' })} />
            {errors.stripe_product_id && <Text color="red.500" fontSize="sm">{errors.stripe_product_id.message?.toString() || ''}</Text>}
          </FormControl>
          {/* Checkbox for Despina Display */}
          <FormControl id="despina_display">
            <Checkbox {...register('despina_display')}>
              Despina Display
            </Checkbox>
          </FormControl>

          <Button type="submit" background="teal.500" textColor="black" width="full" mt="4">
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
