import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

/* eslint-disable-next-line */
export enum SubscriptionStatus {
  Active,
  Away,
  Paused,
  Cancelled,
  PaymentFailed,
  Pending,
}

export type Subscription = {
  id: string;
  sk: string;
  entity_type: number;
  created_at: number;
  updated_at: number;
  deleted_at: number
  customer_id: string;
  stripe_subscription_id: string;
  aggregator_service_id: string;
  avc_id: string;
  plan_id: string;
  manual_transfer: number;
  service_class: string;
  traffic_class: string;
  technology_type: string;
  infrastructure_id: string;
  infrastructure_port: number;
  svlan: string;
  cvlan: string;
  infrastructure_product_id: string;
  poi_id: string;
  poi_name: string;
  lat: number;
  lon: number;
  location_id: string;
  ip: string;
  ip6: string;
  delegated_ip6: string;
  state: string;
  postcode: string;
  suburb: string;
  street: string;
  street_number: string;
  building_name: string;
  unit_number: string;
  building_level: string;
  formatted_address: string;
  status: SubscriptionStatus;
}

export interface subscriptionState {
  value: Array<Subscription>;
  current: Subscription;
  nextPageToken: string;
  previousPageToken: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: subscriptionState = {
  value: [],
  current: {} as Subscription,
  nextPageToken: '',
  previousPageToken: '',
  status: 'idle',
};

export const fetchSubscriptions = createAsyncThunk<
  any,
  string | undefined
>('subscriptions/fetchSubscriptions', async () => {
  const response = await Client.get<Subscription>(
    '/api/v1/admin/neptune/subscriptions',
  );
  return response.data;
});

export const fetchCurrentSubscription = createAsyncThunk<
  any,
  string | undefined
>('subscriptions/fetchCurrentSubscription', async (id) => {
  const response = await Client.get<Subscription>(
    `/api/v1/admin/neptune/subscriptions/${id}`,
  );
  return response.data;
});

export const createSubscription = createAsyncThunk<
  Subscription,
  Subscription,
  {state: RootState}
>('subscriptions/createSubscription', async (svc: Subscription) => {
  const response = await Client.post<Subscription>(
    '/api/v1/admin/neptune/subscriptions',
    svc,
  );
  return response.data;
});

export const updateSubscription = createAsyncThunk<
  Subscription,
  Subscription,
  {state: RootState}
>('subscriptions/updateSubscription', async (svc: Subscription) => {
  const response = await Client.put<Subscription>(
    '/api/v1/admin/neptune/subscriptions',
    svc,
  );
  return response.data;
});

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchCurrentSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentSubscription.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(fetchCurrentSubscription.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [action.payload] : [v]));
      })
      .addCase(updateSubscription.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(createSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createSubscription.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectSubscriptions = (state: RootState) => state.subscriptions.value;
export default subscriptionsSlice.reducer;
